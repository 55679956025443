(function () {
  'use strict';

  angular
    .module('neo.home.cancellations.edit')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.cancellations.edit', {
        url: '^/home/matches/{matchId}/cancellation/edit',
        templateUrl: 'home/cancellations/edit-cancellation/edit-cancellation.tpl.html',
        controller: 'EditCancellationCtrl',
        controllerAs: 'vm',
        resolve: {
          cancellationTypes: function (CancellationTypesPublic) {
            return CancellationTypesPublic.query().$promise;
          },
          match: function (Matches, $stateParams) {
            return Matches.get({id: $stateParams.matchId}).$promise;
          }
        }
      });
  }
}());
